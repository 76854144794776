import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import CircularIndeterminate from './components/CircularProgress';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [contents, setContents] = useState({} as any);

  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS'
  };

  function formatBytes(a: any, b: any = 2) {
    if (!+a) return '0 Bytes';
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${
      ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
    }`;
  }

  useEffect(() => {
    if (!contents.length) {
      const fetchData = async () => {
        const response = await fetch(
          'https://cqj5fximzylbjclzrlwk7aipyu0tvgmu.lambda-url.us-east-1.on.aws/'
        );
        const data = await response.json();
        const newData = data.map((item: any) => {
          const startIndex = item.url.lastIndexOf('/') + 1;
          // const startIndex =
          //   item.url.indexOf('/public-docs/') + '/public-docs/'.length;
          const endIndex = item.url.indexOf('?AWSAccessKeyId=');
          const documentTitle = item.url.substring(startIndex, endIndex);
          let parts = item.key.split('/');
          let directory = '';
          if (parts.length > 2) {
            directory = parts[1];
          } else {
            directory = 'Submission Not Titled';
          }
          return {
            ...item,
            directory: directory,
            parsedTitle: decodeURI(documentTitle)
          };
        });

        const submissionArrays: any = {};

        newData.forEach((obj: any) => {
          const { directory } = obj;
          if (!submissionArrays[directory]) {
            submissionArrays[directory] = [];
          }
          submissionArrays[directory].push(obj);
        });

        // Object.keys(submissionArrays).forEach((key: any) => {
        //   const submissionArray = submissionArrays[key];
        //   window[key] = submissionArray;
        // });

        if (data) setLoading(false);
        // setContents(newData);
        console.log('submissionArrays: ', submissionArrays);

        setContents(submissionArrays);
      };
      try {
        fetchData();
      } catch (error) {
        console.error('error: ', error);
      }
    }
  }, []);

  return (
    <div className="container">
      <div className="title">
        <img
          // className={classes.image}
          src="/brightline.png"
          alt="Header Image"
        />
        {/* <h2>CPUC Crossing Resolution Submissions</h2> */}
        <h2>
          DesertXpress Enterprises, LLC dba Brightline West’s Submissions re:
          CPUC Resolution SX-418
        </h2>
      </div>

      {loading && <CircularIndeterminate />}
      {!loading && (
        <>
          {Object.entries(contents).map(([directory, submissions]: any) => (
            <div className="list-container" key={directory}>
              <h4>{directory}</h4>
              <ul>
                {submissions.map((submission: any) => (
                  <div className="list-item" key={submission.key}>
                    <a href={submission.url} target="_blank">
                      {submission.title || submission.parsedTitle}
                    </a>{' '}
                    - {formatBytes(submission.size)}
                  </div>
                ))}
              </ul>
            </div>
          ))}
        </>

        // <>
        //   <h4>Submission 1</h4>
        //   <div className="list-container">
        //     {contents.map((content: any, idx: number) =>
        //       content.title && content.directory ? (
        //         <div className="list-item" key={idx}>
        //           <a href={content.url}>{content.title}</a>
        //         </div>
        //       ) : (
        //         <div className="list-item" key={idx}>
        //           <a href={content.url}>{content.parsedTitle}</a>
        //         </div>
        //       )
        //     )}
        //   </div>
        // </>
      )}

      {/*
      <h4>Submission 1</h4>
      <div className="list-container">
        <a href="https://cpuc-public-documents.s3.amazonaws.com/public-docs/Desert.jpg">
          Link 1
        </a>
        <a href="https://cpuc-public-documents.s3.amazonaws.com/public-docs/lighthouse.jpg">
          Link 2
        </a>
      </div>
      <h4>Submission 2</h4>
      <div className="list-container">
        <a href="https://cpuc-public-documents.s3.amazonaws.com/public-docs/Desert.jpg">
          Link 1
        </a>
        <a href="https://cpuc-public-documents.s3.amazonaws.com/public-docs/lighthouse.jpg">
          Link 2
        </a>
      </div> */}
    </div>
  );
};

export default App;
